.setting_sidebar {
  width: 24%;
  height: auto;
}

.setting_rightSideContainer {
  width: 76%;
}

.userInformation_container {
  width: 41%;
}
.userDetail_container {
  width: 59%;
}

.danger_gradient {
  background: linear-gradient(90deg, #cb304c 0%, #a92222 100%);
  border-radius: 5px;
  font-size: 12px;
}

.profile_gradient {
  background: linear-gradient(90deg, #d35f1e 0%, #ff6b00 100%);
  border-radius: 20px;
  font-size: 15px;
}

.buttonTab_container {
  width: 40%;
}
.activeTabSetting {
  background-color: #e4f5ff;
  border: 1px solid #529cf3;
  width: 16rem;
  height: 2rem;
  text-align: center;
  border-radius: 50px;
  cursor: pointer;
  padding-top: 3px;
}

.inActiveTab {
  width: 16rem;
  height: 2rem;
  text-align: center;
  border-radius: 50px;
  cursor: pointer;
  padding-top: 3px;
}

.setting_btn {
  width: 5rem;
  height: 2rem;
}

.user_info span {
  padding-top: 8px;
}
#dropdown-setting {
  width: 320px;
  background-color: #ffff;
  color: #000;
}

.addressInfo_container span {
  padding: 5px;
}
.userInformation_container span {
  padding: 5px;
}

.selectedMenuTab {
  background-color: #e4f5ff;
  border-radius: 5px;
  height: 6vh;
  display: flex;
  align-items: center;
  color: #676767;
}

.unSelectedMenuTab {
  border: none;
  border-radius: 20px;
  height: 6vh;
  display: flex;
  align-items: center;
  color: #676767;
}

.loginGraph {
  border: 1px solid #e2e5eb;
  border-radius: 8px;
  width: 60vw;
  height: 37vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.activityContent {
  border-radius: 27px;
  border: 1px solid #e2e5eb;
  background-color: #ffffff;
  width: auto;
}

.activeLogin {
  border-radius: 20px;
  border: 1px solid #529cf3;
  background-color: #e4f5ff;
  color: #515151;
  width: fit-content;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.inActiveLogin {
  font-size: 15px;
  color: #515151;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#dropdown-active-user {
  border: 2px solid #316aff;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #515151;
  font-size: 14px;
}

#dropdown-import-users {
  background-color: #407bff;
  color: #ffffff;
  border: 1px solid #407bff;
  height: 7vh;
}

.addUser {
  background-color: #407bff;
  color: #ffffff;
  border-radius: 5px;
  border: 1px solid #407bff;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}

.searchUser {
  outline: none;
  border-radius: 20px;
  border: 1px solid #e2e5eb;
}

.settings_list {
  overflow-y: auto !important;
  max-height: 20.9vh !important;
  min-height: 12.9vh !important;
  background-color: #f0f0f0;
  top: 51px;
  z-index: 10;
  box-shadow: 0px 0px 10px 0px #0000001f;
}

.settings_profile_list {
  overflow-y: auto !important;
  max-height: 20.9vh !important;
  min-height: 8.9vh !important;
  background-color: #f0f0f0;
  top: 51px;
  z-index: 10;
  box-shadow: 0px 0px 10px 0px #0000001f;
}

.settings_list::-webkit-scrollbar {
  width: 3px;
}

.settings_list::-webkit-scrollbar-thumb {
  background: #adc3ff;
  border-radius: 2px;
}

.loginGraph::-webkit-scrollbar {
  width: 3px;
}

.loginGraph::-webkit-scrollbar-thumb {
  background: #adc3ff;
  border-radius: 2px;
}

.input-with-clear .clear-button-profile {
  position: absolute;
  top: 50%;
  right: 11px;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
}

.input-with-clear .clear-button-export {
  position: absolute;
  top: 50%;
  right: 4%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
}

#dropdown-activity {
  background-color: #e4f5ff;
  border-radius: 8px;
  border: 1px solid #529cf3;
  color: #515151;
  width: fit-content;
  text-align: start;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.settingsDate {
  outline: none;
  border-radius: 5px;
  border: 1px solid #e2e5eb;
}

.react-switch-handle {
  height: 22px !important;
  width: 22px !important;
  top: 3px !important;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 20px;
  transition: 0.4s;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

input:checked + .toggle-slider {
  background-color: #43d66c;
}

input:checked + .toggle-slider:before {
  transform: translateX(20px);
}

.restoreButton {
  background-color: red;
  color: #ffffff;
  border-radius: 5px;
  border: 1px solid red;
  font-size: 14px;
}

.deleteButton {
  background-color: #fff6f6;
  color: #515151;
  border-radius: 8px;
  border: 2px solid #cb304c;
  font-size: 16px;
  font-weight: 500;
}

.usersDisplay {
  background-color: #e4f5ff;
}

.usersDisplay:hover {
  background-color: #e4f5ff !important;
}

.usersDisplay:active {
  background-color: #e4f5ff;
}

.searchGroup {
  outline: none;
  border-radius: 20px;
  border: 1px solid #b9b9b9;
  background-color: #ffffff;
}

.searchUser {
  outline: none;
  border-radius: 1px;
  border: 1px solid #b9b9b9;
  background-color: #ebeef3;
  border-radius: 20px;
}

.recycleBinDetails {
  height: 35vh;
  overflow-y: auto;
}

.recycleBinDetails::-webkit-scrollbar {
  width: 3px;
}

.recycleBinDetails::-webkit-scrollbar-thumb {
  background: #adc3ff;
  border-radius: 2px;
}

.cancelRecycle {
  color: #515151;
  background-color: #e4f2ff;
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
  border: 2px solid #407bff;
}

.inputCreateGroupModal {
  outline: none;
  border: 1.5px solid #b9b9b9;
  border-radius: 5px;
  color: #515151;
  height: 8vh;
  width: 32vw;
}

.deleteOption {
  border-radius: 5px;
  border: 1px solid #b9b9b9;
  color: #9c9c9c;
  font-size: 16px;
  width: 25vw;
}

.noteDelete {
  background-color: #e4f5ff;
  border-radius: 8px;
}

.noteText {
  color: #515151;
  font-size: 20px;
  font-weight: 500;
}
.noteTextDetails {
  color: #9c9c9c;
  font-size: 16px;
  font-weight: 300;
}

.inputBoxTransfer {
  outline: none;
  border: 1.5px solid #b9b9b9;
  border-radius: 5px;
  color: #515151;
  /* height: 40px; */
  /* width: 20vw; */
  width: 320px;
  height: 42px;
}

.settings_list_transfer {
  overflow-y: auto !important;
  max-height: 20.9vh !important;
  min-height: 20.9vh !important;
  background-color: #f0f0f0;
  width: 23.5vw;
  top: 51px;
  z-index: 10;
  box-shadow: 0px 0px 10px 0px #0000001f;
  overflow-x: hidden !important;
}

.settings_list_transfer::-webkit-scrollbar {
  width: 3px;
}

.settings_list_transfer::-webkit-scrollbar-thumb {
  background: #adc3ff;
  border-radius: 2px;
}

.editUserModal::-webkit-scrollbar {
  width: 3px;
}

.editUserModal::-webkit-scrollbar-thumb {
  background: #adc3ff;
  border-radius: 2px;
}

.custom-checkbox {
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
}

.custom-checkbox:checked {
  background-color: green;
  border: none;
}

.custom-checkbox:checked::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 6px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.editHeader {
  color: #515151;
  font-size: 16px;
  font-weight: 300;
}

.editCompanyHeader {
  color: #515151;
  font-size: 15px;
  font-weight: 300;
}

.inputBoxCompanyDetails {
  outline: none;
  border: 1.5px solid #b9b9b9;
  border-radius: 5px;
  color: #515151;
  /* height: 40px; */
  /* width: 20vw; */
  width: 270px;
  height: 42px;
}

.inputBoxProfile {
  outline: none;
  border: 1.5px solid #b9b9b9;
  border-radius: 5px;
  color: #515151;
  width: 365px;
  height: 42px;
}

.inputBoxExport {
  outline: none;
  border: 1.5px solid #b9b9b9;
  border-radius: 5px;
  color: #515151;
  width: 25vw;
  height: 42px;
}

.inputBoxNewRole {
  outline: none;
  border: 1.5px solid #b9b9b9;
  border-radius: 5px;
  color: #515151;
  width: 320px;
  height: 42px;
}

.node-label {
  background-color: #ffffff;
  border: 2px solid #407bff;
  border-radius: 25px;
  padding: 5px 8px;
  cursor: pointer;
  text-align: center;
  margin: 0px auto;
  position: relative;
  font-size: 16px;
  display: inline-block;
}

.hover-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0%;
  left: auto;
}

.hover-icon {
  position: relative;
  top: 10%;
  transform: translateY(-50%);
  background-color: #deeafb;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  margin: 0 2px; /* Adjust margin to space out icons */
  z-index: 1;
}

.settingsRole {
  overflow-x: scroll;
  overflow-y: scroll;
}

.settingsRole::-webkit-scrollbar {
  width: 3px;
}

.settingsRole::-webkit-scrollbar-thumb {
  background: #adc3ff;
  border-radius: 2px;
}

.RenameClick {
  outline: none;
  border: 1.5px solid #b9b9b9;
  border-radius: 5px;
  color: #515151;
  width: 365px;
}

.social-media-container {
  position: relative;
  display: inline-block;
}

.delete-icon {
  position: absolute;
  top: 0px;
  right: 5px;
  display: none;
  cursor: pointer;
}

.delete-note-icon {
  position: absolute;
  right: 40px;
}
.delete-note-icon-true {
  position: absolute;
  top: 0;
  left: -7px;
}

.social-media-container:hover .delete-icon {
  display: block;
}

.loginActivity:hover {
  background-color: #e4f5ff;
}

.storageButton {
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  text-align: left;
  color: #515151;
}
.groupClassActive {
  height: 84vh;
}

.responsive-div {
  height: 53vh !important;
  overflow-y: auto;
}

.responsive-div {
  overflow-x: scroll;
  overflow-y: scroll;
}

.responsive-div::-webkit-scrollbar {
  width: 3px;
}

.responsive-div::-webkit-scrollbar-thumb {
  background: #adc3ff;
  border-radius: 2px;
}

.membersListClass::-webkit-scrollbar {
  width: 3px;
}

.membersListClass::-webkit-scrollbar-thumb {
  background: #adc3ff;
  border-radius: 2px;
}

.exportTableClass {
  border-radius: 15px;
  border: 2px solid #e2e5eb;
  height: 57vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.exportTableClass::-webkit-scrollbar {
  width: 3px;
}

.exportTableClass::-webkit-scrollbar-thumb {
  background: #adc3ff;
  border-radius: 2px;
}

.exportHeader {
  color: #676767;
  font-weight: 300;
  font-size: 16px;
}

.exportData:hover {
  background-color: #f5f5f5;
}

.dragAndDrop {
  cursor: grabbing;
}

.form-check:hover {
  background-color: #f5f5f5;
}

.locationClass{
  width:17%
}

/* Define height for large screens */
@media only screen and (min-width: 1920px) {
  .responsive-div {
    height: 70vh !important;
    overflow-y: auto;
  }
}

@media only screen and (min-width: 1920px) {
  .groupClassActive {
    height: 90vh !important;
    overflow-y: auto;
  }
}

@media only screen and (min-width: 1920px) {
  .recycleBinDetails {
    height: 60vh !important;
    overflow-y: auto;
  }
}
@media only screen and (min-width: 1920px) {
  .loginGraph {
    border: 1px solid #e2e5eb;
    border-radius: 8px;
    width: 61vw;
    height: 37vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media only screen and (min-width: 1920px) {
  .exportTableClass {
    height: 52vh;
  }
}

.widthContainerData {
  width: max-content;
  border: 1.95px solid #e7e9ee;
  height: 50px;
  font-weight: 600;
  color: #515151;
  font-size: 16px;
}

.recordContainer {
  border: 2.86px solid #e2e5eb;
  box-shadow: 0px 0px 18.12px 0px #00000008;
  width: 75%;
}

@media screen and (min-width: 1920px) {
  .widthContainerData {
    width: 21%;
    border: 1.95px solid #e7e9ee;
    height: 50px;
    font-weight: 600;
    color: #515151;
    font-size: 16px;
  }
  .recordContainer {
    border: 2.86px solid #e2e5eb;
    box-shadow: 0px 0px 18.12px 0px #00000008;
    width: 50%;
  }
}

@media screen and (min-width: 1400px) {
  .recycleBinDetails {
    height: 45vh;
  }
}

@media only screen and (min-width: 1920px) {
  .locationClass {
 width:11%
  }
}
