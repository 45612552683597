.mainBody {
  display: flex;
  width: 100%;
  height: auto;
}

.sidebar {
  display: flex;
  width: 14%;
  background-color: #fff;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  transition: all 0.25s ease;
  position: fixed !important;
  left: 0;
  top: 0;
  z-index: 5;
  height: 100vh;
}

.rightSideSmall {
  display: flex;
  margin-left: 8% !important;
  width: 92% !important;
  background-color: #f3f6fb;
  margin: 0px 10px;
  padding: 10px;
  border-radius: 20px;
  flex-direction: column;
  gap: 10px;
}

.rightSide {
  display: flex;
  width: 86% !important;
  margin-left: 14%;
  background-color: #f3f6fb;
  /* margin: 10px 5px; */
  padding: 10px;
  border-radius: 20px;
  flex-direction: column;
  /* height: 400vh; */
  /* gap: 10px; */
}

.topBarSearch {
  border: 2px solid rgba(162, 161, 168, 0.2);
  border-radius: 25px;
  outline-style: none;
  height: 44px;
  width: 20vw;
  background-color: #f3f6fb;
}

.topBar {
  display: flex;
  align-items: center;
  height: 70px;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;
  border-bottom: 3px solid #fff;
}

.inputContainer {
  position: relative;
}

.searchIcon {
  position: absolute;
  left: 16px; /* Adjust this value to position the icon */
  top: 50%;
  transform: translateY(-50%);
}

.welcomeBar {
  display: flex;
  padding: 5px 10px;
  /* height: 70px; */
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}

.welcomeLeft {
  display: flex;
  align-items: center;
  gap: 10px;
}

.welcomeRight {
  display: flex;
  align-items: center;
  gap: 10px;
}

.optionMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  /* padding: 5px; */
  border: 2px solid #316aff;
  border-radius: 10px;
  width: 5vw;
  height: 40px;
  font-size: 30px;
  color: #316aff;
}

.lineTwo {
  margin-bottom: 0;
  background: linear-gradient(180deg, rgba(167, 80, 255, 0.5) 0%, #8f1fff 100%);
  /* height: auto; */
  width: 0.5vw;
  border-radius: 10px;
}

.lineThree {
  margin-bottom: 0;
  background: linear-gradient(180deg, rgba(255, 181, 82, 0.5) 0%, #f0951d 100%);
  /* height: auto; */
  width: 0.5vw;
  border-radius: 10px;
}

.lineFour {
  margin-bottom: 0;
  background: linear-gradient(180deg, rgba(79, 190, 255, 0.5) 0%, #12a7fe 100%);
  /* height: auto; */
  width: 0.5vw;
  border-radius: 10px;
}

.sideBarItemsActive {
  display: flex;
  width: 87%;
  padding: 9px 0;
  border-radius: 5px;
  /* background: linear-gradient(86.07deg, #407bff 0%, #12a7fe 100%); */
  color: #fff;
  cursor: pointer;
  align-items: center;
}

.sideBarItemsDisable {
  display: flex;
  width: 87%;
  padding: 9px 0;
  border-radius: 5px;
  cursor: pointer;
  color: #676767;
  align-items: center;
}

.sideBarItemsDisable:hover {
  background: #e7eeff;
  cursor: pointer;
  align-items: center;
  /* color: #fff; */
}
.relatedList_tab:hover:hover {
  background: #e4f5ff;
  cursor: pointer;
}

.sidebarSmall {
  display: flex;
  width: 8%;
  background-color: #fff;
  padding: 15px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  transition: all 0.25s ease;
  position: fixed !important;
  left: 0;
  top: 0;
  z-index: 5;
  height: 100vh;
}

.sideBarAcSmall {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 5px; */
  width: 100%;
  padding: 5px 0;
  border-radius: 10px;
  cursor: pointer;
  /* background: linear-gradient(86.07deg, #407bff 0%, #12a7fe 100%); */
  color: #fff;
}

.sideBarDiSmall {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 5px; */
  width: 100%;
  padding: 2px 0;
  border-radius: 10px;
  cursor: pointer;
  color: #b9b9b9;
}

.sideBarDiSmall:hover {
  /* background: linear-gradient(86.07deg, #407bff 0%, #12a7fe 100%); */
  background: #e7eeff;
  cursor: pointer;
}

.moreModal {
  position: absolute;
  left: 100%;
  /* bottom: 33%; */
  margin-left: 10px;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 5;
  display: block;
  width: 20vw;
  /* margin-top: 7rem; */
  /* z-index: 1 !important; */
  top: -16rem;
}
.sidebar_logo {
  height: 100%;
  width: 100%;
}
.shrink_icon {
  top: 4.4rem;
  right: -0.8rem;
}

.table .tableData {
  max-height: 20vh;
  overflow-y: auto;
  overflow-x: hidden;
}

#table-scroll {
  max-height: 29vh;
  overflow-y: auto;
  overflow-x: auto;
}

#table-scroll table {
  width: 100%;
  border-collapse: collapse;
}

#table-scroll thead th {
  color: #515151;
  font-size: 14px;
  font-weight: 600;
  position: sticky;
  top: 0;
  z-index: 2;
}
#table-scroll tbody td {
  font-size: 14px;
}

#table-scroll tbody {
  overflow-y: auto;
  overflow-x: auto;
}

#table-scroll::-webkit-scrollbar,
.dashboard_container::-webkit-scrollbar {
  width: 5px;
}

#table-scroll::-webkit-scrollbar-thumb,
.dashboard_container::-webkit-scrollbar-thumb {
  background-color: #adc3ff;
  /* background-color: #316aff; */
  border-radius: 10px;
}

#table-scroll::-webkit-scrollbar-track,
.dashboard_container::-webkit-scrollbar-track {
  background-color: rgb(245, 245, 245);
}

#table-scroll::-webkit-scrollbar:horizontal {
  height: 5px;
  background-color: #adc3ff;
  border-radius: 10px;
}

.profileInfoContainer {
  display: block;
  position: absolute;
  top: 3.6rem;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 5;
  right: -0.9rem;
  min-width: 14rem;
  max-width: 18rem;
}

.triangle-container {
  border: 1px solid #d9d9d9;
  border-right: none;
  border-bottom: none;
  transform: rotate(45deg);
}

.triangle {
  width: 1.3rem;
  height: 1.3rem;
  background-color: #ffffff;
}

.triangleClass {
  position: absolute;
  top: -9.5%;
  right: 9%;
}

.sideBarIcon {
  display: flex;
  height: 25px;
  width: 30%;
}
.sidebarSmallName {
  font-size: 14px;
  /* width: 50%; */
  margin-bottom: 0 !important;
}

.dropdown-toggle::after {
  /* color: #2177fe; */
  /* color: #ffffff; */
}

.table > :not(caption) > * > * {
  color: #515151;
  font-weight: 500;
  font-size: 15px;
}

.border_bottom {
  border-bottom: 2px solid #316aff;
}
.dollar_icon {
  margin-top: 1.8rem;
}
.tdInputStyleObj {
  margin-bottom: 10px;
  border-radius: 2.5px;
  border: 1px solid rgba(185, 185, 185, 0.5);
  width: -webkit-fill-available;
}
.add_comp_dropdown {
  width: 10rem;
  background-color: #ffffff;
  border: 1px solid rgba(185, 185, 185, 0.5);
  z-index: 2;
}
.component_container {
  width: 26vw;
  height: 38vh;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 20px 0px #0000000d;
}
.component_header {
  background-color: rgba(33, 119, 254, 0.15);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.deleteIcon {
  background-color: rgba(255, 233, 233, 1);
}
.editIcon {
  background-color: rgba(33, 119, 254, 0.1);
}
.dashboard_container {
  height: 74vh;
  overflow-y: auto;
}
.text-group text {
  fill: unset !important;
}
.needle {
  display: none;
}
.arc_chart {
  height: 100%;
  width: 100%;
  background-color: #12a7fe;
  border-radius: 50%;
}
.apexcharts-toolbar {
  display: none !important;
}
#funnelChart div svg,
#columnChart div svg {
  margin-top: -1.4rem !important;
}
.funnel_chart .chart div {
  height: 34vh !important;
  margin-top: -0.4rem !important;
}
.funnel_chart_analytics .chart div {
  height: 45vh !important;
}
/* #doughnutChart div {
  height: 120%;
} */
#doughnutChart div svg {
  height: 100%;
}
.no_data_img img {
  height: 8.6em;
}
.gauge_chart .apexcharts-datalabels-group {
  transform: scaleY(0.98);
}
.upgradeClass {
  background: linear-gradient(
    to right,
    rgba(139, 192, 255, 0.4),
    rgba(112, 0, 255, 0.3)
  );
  border-radius: 10px;
}

.upgrade {
  border-radius: 30px;
  border: 2px solid rgba(222, 199, 255, 1);
  width: 8vw;
  background-color: #ffffff;
}
@media only screen and (max-width: 1280px) {
  .component_container {
    height: 42vh;
  }
}
@media only screen and (min-width: 1920px) {
  .sideBarIcon {
    height: 30px;
  }
  .component_container {
    width: 26.7vw;
  }
  .dashboard_container {
    height: 84vh;
  }
  .funnel_chart .chart div {
    height: 33.5vh !important;
  }
  .moreModal {
    /* bottom: 50% !important; */
    margin-left: 8px !important;
    width: 13vw !important;
  }
}
.sidebarOverIcons {
  width: 24px;
  height: 24px;
}
.sideBarOverItems {
  display: none;
}
.position-relative:hover > .sideBarOverItems {
  display: block;
}
.sideBarOverItems {
  top: 0;
  transform: translateX(60%);
  right: -90px; /* we need to increase 10px to make exactly look like figma */
  z-index: 5;
  background-color: #ffffff;
  width: 243px;
  box-shadow: 0px 0px 30px 0px #0000001a;
  border-radius: 6px;
}
.sidebarItems {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 12px;
  padding-left: 15px;
}
.sidebarItems:hover {
  background: #e7eeff;
  border-radius: 6px;
}

.searchTable {
  border-collapse: collapse;
  border: 1px solid #dedfdf;
  border-radius: 10px !important;
}

.searchTable th,
.searchTable td {
  border-bottom: 1px solid #dedfdf;
  padding: 8px;
  text-align: left;
}

.searchTable th {
  color: #515151;
  font-weight: 300;
  font-size: 16px;
}

.searchedData {
  color: #2177fe;
  font-size: 16px;
  cursor: pointer;
}
.notification_dropdown {
  width: 23rem;
  left: -10.3rem;
  top: 3rem;
  background-color: #ffffff;
  z-index: 10;
}
.notification_triangle {
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #2177fe;
  left: 46%;
  top: -12px;
}
.notification_data {
  max-height: 20rem;
  overflow-y: auto;
}
.dot {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: transparent;
}
.dotRead {
  background-color: #12a7fe;
}
/* .readalloption {
  font-size: 12px;
} */

.readalloption:hover {
  text-decoration: none;
}
.dashboard_container li {
  list-style: none;
}
.notificationTabSelected {
  border-bottom: 1px solid #12a7fe;
}
.notification_count {
  background-color: #24a5ee;
  /* padding: 1px 5px 1px 5px; */
  border: 2px solid #ffffff;
  top: -0.6rem;
  right: -0.4rem;
  font-size: 0.7rem;
  width: 1.55rem;
  height: 1.55rem;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

.modalSearch {
  border: none;
  outline: none;
  height: 40px;
  width: 35vw;
  border-bottom: 1px solid #515151;
}

.searchHeader.modal-header {
  border-bottom: none;
}

.hide-text:hover {
  text-decoration: underline;
}

.checkboxButton {
  width: 14vw;
  background-color: #ffffff;
  border: 2px solid #316aff;
  border-radius: 10px;
  display: flex;
  justify-content: start;
  align-items: center;
  position: relative;
}
.checkboxButton::after {
  content: url("../../Assets/arrowDownBlue.svg");
  transform: rotate(-90deg);
  position: absolute;
  right: 12px;
}
.checkboxButton::after:hover {
  border: 2px solid #515151;
  opacity: 0.6;
}

.checkboxButton:hover {
  border: 2px solid #515151;
  opacity: 0.6;
}

.labelText {
  width: 9vw;
}
.recharts-legend-wrapper ul {
  display: flex !important;
  flex-wrap: wrap !important;
}
.sharemodal_header_one {
  width: 50vw;
}
.sharemodal_header_two {
  width: 25vw;
}
.recharts-default-legend li {
  display: flex !important;
  align-items: center;
}
@media only screen and (min-width: 1920px) {
  .checkboxButton {
    width: 9vw;
  }

  .checkboxButton::after {
    position: absolute;
    right: 18px;
  }

  .labelText {
    width: 5vw;
  }
}
/* .notification_dropdown ul li span:first-child {
  width: 84%;
  float: left;
}

.notification_dropdown ul li span:last-child {
  width: 14%;
  float: right;
} */
