/* @import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap");

body {
  margin: 0;
  font-family: "Lexend", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

@font-face {
  src: url("../src/Stylesheet/NewFont/helvetica-255/Helvetica.ttf");
  font-family: Helvetica;
}

html,
body {
  font-family: Helvetica, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
