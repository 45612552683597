.creteLeadBar {
  display: flex;
  width: 100%;
  height: 70px;
  align-items: center;
  /* padding: 0 10px; */
}

.totalRecordBar {
  display: flex;
  width: 100%;
  height: 70px;
  align-items: center;
  padding: 0 10px;
}

.leadMain {
  display: flex;
  gap: 10px;
}

.filterLead {
  display: flex;
  flex-direction: column;
  width: 30%;
  background-color: #fff;
  height: 80vh;
  border-radius: 10px;
  padding: 10px;
  gap: 10px;
}

.leadListTable {
  /* display: flex; */
  width: 100%;
  background-color: #fff;
  height: 55vh;
  border-radius: 10px;
  overflow-y: auto;
}

.leadListTableFullWidth {
  /* display: flex; */
  width: 100%;
  background-color: #fff;
  height: 100vh;
  border-radius: 10px;
  overflow-y: auto;
}

.table.leadTable th,
.table.leadTable td {
  white-space: nowrap;
}

.table.leadTable th {
  font-weight: 500;
}
.table.leadTable td {
  font-weight: 400;
}

.leadTable thead th {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  /* z-index: 1; */
}
.table.leadTable tbody tr:hover {
  background-color: #12a7fe !important;
}

.leadListTable::-webkit-scrollbar {
  width: 5px;
  /* Width of the scrollbar */
  height: 5px;
}

.leadListTable::-webkit-scrollbar-thumb {
  background-color: #adc3ff;
  /* Color of the scrollbar thumb */
  border-radius: 10px;
  /* Rounded corners for the thumb */
}

.leadListTable::-webkit-scrollbar-track {
  background-color: transparent;
  /* Color of the track */
}

.leadListTableFullWidth::-webkit-scrollbar {
  width: 5px;
  /* Width of the scrollbar */
  height: 10px;
}

.leadListTableFullWidth::-webkit-scrollbar-thumb {
  background-color: #adc3ff;
  /* Color of the scrollbar thumb */
  border-radius: 10px;
  /* Rounded corners for the thumb */
}

.leadListTableFullWidth::-webkit-scrollbar-track {
  background-color: transparent;
  /* Color of the track */
}

.filterSearch {
  border: 2px solid #a2a1a833;
  border-radius: 10px;
  outline-style: none;
  height: 35px;
  /* width: 30%; */
}

.checkboxItem {
  color: #9c9c9c;
}

.createLeadButtonBar {
  display: flex;
  width: 100%;
  height: 70px;
  align-items: center;
  padding: 0 10px;
  border-bottom: 3px solid #fff;
}

.createLeadButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  border: 2px solid #316aff;
  border-radius: 10px;
}
/* 
.createLeadButton:hover {
  background: linear-gradient(86.07deg, #407bff 0%, #12a7fe 100%);
  color: #fff;
  border: none;
} */

.leadInputField {
  width: 60% !important;
  border: 1px solid #b9b9b94d;
  height: 40px;
  outline: none;
  border-radius: 10px;
}

.leadFields {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 10px;
}

.inputFieldDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.leadFilter {
  border: 2px solid #316aff;
  padding: 5px;
  border-radius: 10px;
  width: 40px;
  height: fit-content;
  cursor: pointer;
}
.leadInfoName:hover {
  border: 1px solid black;
}

/* individualDetailPage css  */

.logoContainer {
  height: 7rem;
  width: 9rem;
  /* border: 1px solid black; */
  border-radius: 50%;
  position: relative;
}

.btnColor {
  background: linear-gradient(86.07deg, #407bff 0%, #12a7fe 100%) !important;
}

.relatedListContainer ul {
  list-style: none;
  margin: 2px 0 0 0;
  padding: 10px;
}

.relatedListContainer li {
  padding-top: 8px;
  display: flex;
  gap: 5px;
}

.horizontal_line {
  border: 0.5px solid #D8D8D8 ;
}

.hrLine {
  margin: 0;
  padding: 0;
  padding-bottom: 5px;
}
.leadName:hover {
  color: #316aff !important;
}
.selectTab {
  background-color: rgba(228, 245, 255, 1) !important;
  border-radius: 10px;
}
.selectedBorder {
  border: 1px solid red !important;
}

.unselectBorder {
  border: none;
  border-bottom: 1px solid rgb(226, 226, 226) !important;
  outline: none;
  cursor: not-allowed;
}

.indvEditInput:hover {
  outline: 1px solid gray;
}

.vertical_line {
  border-left: 2px dashed #316aff !important;
  border-top: none !important;
  border-right: none !important;
  border-bottom: none !important;
  height: 54px !important;
  /* border-style: dashed !important; */
}

.timelineBg_color {
  background-color: #e4f5ff;
  width: 20%;
}

.historyMain_Container {
  height: 72vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #316aff !important;
}

.historyMain_Container::-webkit-scrollbar {
  width: 4px;
}

.historyMain_Container::-webkit-scrollbar-thumb {
  background-color: #adc3ff;
  border-radius: 2px;
}

.upcomingAction_container {
  height: 25vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #316aff !important;
}

.upcomingAction_container::-webkit-scrollbar {
  width: 4px;
}

.upcomingAction_container::-webkit-scrollbar-thumb {
  background-color: #adc3ff;
  border-radius: 2px;
}

.convertLabel span {
  padding: 8px;
}

.convertInput {
  width: 50%;
}

.s {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  height: 10vh;
}

.table-wrapper {
  max-width: 100%;
  height: 58vh;
  overflow-y: auto;
  overflow-x: auto;
  position: relative; /* Add this line */
}

.productsTabledata {
  color: #9c9c9c;
  font-weight: 400;
  font-size: 16px;
  padding: 10px;
}

.productTableHeader {
  color: #515151;
  font-weight: 400;
  font-size: 14px;
}

.cancelButton {
  border-radius: 5px;
  border: 2px solid #316aff;
  background-color: #ffffff;
  color: #515151;
  font-size: 16px;
  font-weight: 500;
  padding: 5px;
}

.unassignButton {
  border-radius: 5px;
  background: linear-gradient(
    to right,
    rgba(64, 123, 255, 1),
    rgba(18, 167, 254, 1)
  );
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  border: 2px solid rgba(18, 167, 254, 1);
  padding: 5px;
}

.borderBottom {
  border-bottom: 2px solid rgba(18, 167, 254, 1);
  width: 10vw;
}

#dropdown-note {
  background-color: #e4f5ff;
  border-radius: 8px;
  border: 1px solid #529cf3;
  color: #515151;
  width: 10vw;
  text-align: start;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-item:hover {
  background-color: #e4f5ff;
}

.table-wrapper::-webkit-scrollbar {
  width: 6px;
}

.table-wrapper::-webkit-scrollbar-thumb {
  background: #adc3ff;
  border-radius: 4px;
}

.Owner_table_container {
  height: 20%;
  margin-top: 12px;
  border: 2px solid #e2e5eb;
  padding: 0;
  /* Maximum height of the table container */
  overflow: hidden;
  /* Hide any overflow outside the container */
}

/* .table-wrapper tbody {
  max-height: calc(100% - 40px) !important;
  overflow-y: auto !important;
} */

.userdata-table {
  width: 100%;
  border-collapse: collapse;
}

.userdata-table th,
.userdata-table td {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 15px 8px;
  text-align: left;
}

.userdata-table th {
  position: sticky;
}
.userdata-table td {
  color: #999999;
}

.userdata-table thead th {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 1; /* Increase the z-index value */
  border: none;
}

.userdata-table tr:hover {
  background-color: #e5f0ff;
  /* Change background color on hover */
  cursor: pointer;
  /* Change cursor to pointer on hover */
}

.userdata-table th:first-child,
.userdata-table td:first-child {
  border-left: none;
  /* Remove left border for first column */
}

.userdata-table th:last-child,
.userdata-table td:last-child {
  border-right: none;
  /* Remove right border for last column */
}

.input-with-clear {
  position: relative;
}

.input-with-clear input[type="text"] {
  padding-right: 50px;
  /* Adjust this value to accommodate the clear button */
  background-color: transparent;
  border-radius: 5px;
}
.indivOwnerInput {
  background-color: transparent !important ;
  border-radius: 5px !important ;
}

.input-with-clear .clear-button {
  position: absolute;
  top: 50%;
  right: 219px !important;
  /* Adjust this value to position the clear button */
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
}

.input-with-clear .clear-button-owner {
  position: absolute;
  top: 50%;
  right: 28px !important;
  /* Adjust this value to position the clear button */
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
}

.lead_ownerList_container {
  overflow-x: hidden;
  overflow-y: auto !important;
  max-height: 26.9vh !important;
  min-height: 25.9vh;
  background-color: #f0f0f0;
  width: 24.5vw;
  top: 336px;
  z-index: 10;
  box-shadow: 0px 0px 10px 0px #0000001f;
}

.lead_ownerList_container::-webkit-scrollbar {
  width: 3px;
  height: 0.2rem;
}

.lead_ownerList_container::-webkit-scrollbar-thumb {
  background: #adc3ff;
  border-radius: 2px;
}

.tableUserData {
  overflow-y: auto !important;
  /* height: 20vh; */
  width: 19.2vw;
}

.tableUserData::-webkit-scrollbar {
  width: 3px;
}

.tableUserData::-webkit-scrollbar-thumb {
  background: #adc3ff;
  border-radius: 2px;
}

.table-body {
  max-height: 200px; /* Set your desired height */
  overflow-y: auto;
}

.lead_ownerList_container .list-group-item {
  background-color: #ffffff;
}

.lead_ownerList_container .list-group-item:hover {
  background-color: #e5f0ff;
}

.leadInformation_content_container span {
  padding: 5px;
}

.contact_container span {
  opacity: 0.8;
}

/* New CSS */
.save_btn,
.cancel_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  border: 2px solid #316aff;
  border-radius: 10px;
}
.save_btn {
  background: linear-gradient(86.07deg, #407bff 0%, #12a7fe 100%);
  color: #fff;
}
.add_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem 1.2rem;
  /* width: 80px; */
  border: 1px solid #316aff;
  border-radius: 5px;
  background: linear-gradient(86.07deg, #407bff 0%, #12a7fe 100%);
  color: #fff;
}

.add_btn:hover {
  background: linear-gradient(86.07deg, hsl(221, 58%, 50%) 0%, #12a7fe 100%);
}

.add_btn:active {
  background-color: #316aff;
  transform: scale(0.95);
}

.add_btn:focus {
  outline: none;
  box-shadow: linear-gradient(86.07deg, hsl(221, 58%, 50%) 0%, #12a7fe 100%);
}

.columns_container {
  max-height: 60vh;
  overflow-y: auto;
}
.filter_type:hover {
  background-color: rgb(81, 81, 81, 0.15);
  border-radius: 4px;
}
.field_container {
  /* background-color: #f5f5f5; */
  height: 30vh;
  z-index: 10;
}
.field_container_dropdown {
  background-color: rgb(244, 244, 244);
  height: 30vh;
  overflow-y: auto;
  z-index: 2;
}

.field_container_dropdown_tasks {
  background-color: rgb(244, 244, 244);
  height: 22vh;
  overflow-y: auto;
  z-index: 2;
}
.outline_border {
  outline: 1px solid rgb(225, 222, 222);
  border: none;
}
.right_tick {
  width: 0.3rem;
}

.notesClass {
  border-radius: 5px;
  border: none;
  outline: none;
  background-color: #ffffff;
  color: #515151;
  font-weight: 300;
  resize: none;
}

.cancelNoteButton {
  background-color: #e4f5ff;
  border-radius: 20px;
  border: 1px solid #316aff;
  color: #515151;
}

.addProductsButton {
  background-color: #e4f5ff;
  border-radius: 8px;
  border: 1px solid #529cf3;
  padding: 1px 24px;
}

.notesHeader {
  color: Black;
  font-weight: 500;
  font-size: 16px;
}
.mass_heading {
  background-color: #f2f2f2;
  color: #3d7eff;
  font-weight: 500;
}
.mass_transfer_user {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: rgba(0, 0, 0, 0.05);
}
.anchorLinkHash {
  text-decoration: none !important;
  cursor: pointer !important;
  font-size: 14px;
  color: #3d7eff;
}

a.anchorLinkHash:hover {
  position: relative;
}

a.anchorLinkHash:hover::after {
  content: attr(data-title);
  background-color: #3d7eff;
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 14px;
  display: block;
  position: absolute;
  top: -40px;
  right: 0%;
  transform: translateX(0%);
  white-space: nowrap;
  z-index: 1;
}
.selectBox .input-with-clear{
  max-width: 65%;

}
.selectBox .leadInputField.cursorPointer{
  width: 100% !important;
}
.selectBox .input-with-clear .clear-button{
  right: 40px !important
}

@media only screen and (min-width: 1920px) {
  .leadListTable {
    height: 73vh !important;
  }
}

@media screen and (min-width: 1920px) {
  .input-with-clear .clear-button {
    right: 330px !important;
  }
}
@media screen and (max-width: 1600px ) {
  .input-with-clear .clear-button {
    right: 198px !important;
  }
}
@media screen and (max-width: 1900px) {
  .input-with-clear .clear-button {
    right: 219px !important;
  }
}
