.createLeadButtonBar {
  display: flex;
  width: 100%;
  height: 70px;
  align-items: center;
  padding: 0 10px;
  border-bottom: 3px solid #fff;
}

.createLeadButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 86px;
  height: 38px;
  border: 2px solid #316aff;
  border-radius: 6px;
  padding: 10px 19px 10px 20px;
  background: white;
}

/* .createLeadButton:hover {
      background: linear-gradient(86.07deg, #407BFF 0%, #12A7FE 100%);
      color: #fff;
      border: none;
  } */

.save-button {
  background: linear-gradient(86.07deg, #2266f9 0%, #12a7fe 100%);
  color: #fff;
  border: none;
  border-radius: 6px;
  width: 89px;
}

.quotesInputFieldDivLeft {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 3px;
  padding-top: 1rem;
}

.quotesInputFieldDivLeft input {
  width: 60%;
  border: 1px solid #b9b9b94d;
  height: 40px;
  outline: none;
  border-radius: 10px;
  background: transparent;
}

.quotesInputFieldDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.quotesInputFieldDivRight {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 3px;
  padding-top: 1rem;
}

.last-button {
  border: none;
  color: red;
}

.last-button:hover {
  border: none;
  color: red;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.inputFieldDivCalls {
  display: flex;
  align-items: center;
  width: 100%;
}

.inputFieldDivLeftCalls {
  width: 100%;
}

.inputFieldDivRightCalls {
  width: 100%;
}

.insertCommonInputFieldCalls {
  width: 95%;
  border: 1px solid #b9b9b9b0;
  height: 50px;
  outline: none;
  background-color: transparent;
  border-radius: 4px !important;
  padding: 12px;
}

.input-with-clear input[type="text"] {
  padding-right: 50px;
  /* Adjust this value to accommodate the clear button */
  background-color: transparent;
  border-radius: 5px;
}

.input-with-clear input:disabled {
  background-color: #f2f2f2; /* Change the color to your desired color */
}

.insertCommonInputFieldCalls:disabled {
  background-color: #f2f2f2;
  color: #999;
}

.insertCommonInputFieldCalls::placeholder {
  color: #9c9c9c;
}

.clear-buttonCalls {
  position: absolute;
  top: 43%;
  right: 38px;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
}

.CallsHeading {
  font-family: Lexend;
  font-size: 22px;
  font-weight: 500;
  line-height: 27.5px;
  text-align: left;
  color: #515151;
}

.callsTimeline {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 17.5px;
  text-align: center;
  color: rgba(81, 81, 81, 1);
}

@media screen and (min-width: 1920px) {
  .clear-buttonCalls {
    top: 43%;
    right: 38px !important;
  }
}
.hours_options,
.minutes_options {
  background-color: rgb(235, 231, 231);
  max-height: 12rem;
  overflow-y: auto;
  z-index: 2;
}

.usersDisplayCalls {
  background-color: #e4f5ff;
}

.infoCall {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  height: 25px;
  left: 63px;
  color: #515151;
}

.infoTextCall {
  font-size: 18px;
  font-weight: 400;
  line-height: 17.5px;
  text-align: left;
  color: #9C9C9C;
  align-self: center;
}

.insertCommonInputFieldCalls {
  /* Your existing input field styles */
  padding-right: 25px; /* Ensure space for the icon */
}

.input-container {
  position: relative;
}

.disabled-icon {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  font-size: 18px;
  color: red; /* Example color for disabled icon */
  pointer-events: none; /* Ensures the icon does not interfere with input field */
  cursor: pointer; /* Change cursor to pointer when hovering */
}

.width_container {
  width: 100%;
}

@media screen and (min-width: 1920px) {
  .width_container {
    width: 70%;
  }
  
.infoCall {
  font-size: 20px;
  font-weight: 400;
  text-align: left;
  height: 25px;
  left: 63px;
  color: #515151;
}
}
