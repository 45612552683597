.signUpContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background: #FFFFFF;

    position: relative;
    overflow: scroll;
}

.signupBtn {
    background: transparent;
    outline: none;
    height: 45px;
    width: 20vw;
    color: #407BFF;
    border: 2px solid #407BFF;
    /* border-image: linear-gradient(86.07deg, #407BFF 0%, #12A7FE 100%) 1; */
    border-radius: 10px;
}

.signupBtn :hover {
    background: linear-gradient(86.07deg, #407BFF 0%, #12A7FE 100%);
    /* background: #407BFF; */
    color: #fff;
}

.signUpSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80vh;
    width: 70vw;
    background: #FFFFFF80;
    box-shadow: 0px 0px 50px 0px #0000000D;
    border-radius: 10px;
    padding: 25px 30px;
    z-index: 999;
    gap: 45px;
}
.signUpAcceptSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80vh;
    width: 70vw;
    background: #FFFFFF80;
    box-shadow: 0px 0px 50px 0px #0000000D;
    border-radius: 10px;
    padding: 25px 30px;
    gap: 45px;
}

.signUpSectionDetails {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 65vw;
    background: #FFFFFF80;
    box-shadow: 0px 0px 50px 0px #0000000D;
    border-radius: 10px;
    padding: 25px 30px;
    z-index: 999;
    gap: 35px;
    margin: 15vh 0;
}

.signUpContainerDetails {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: linear-gradient(to right,
    rgba(126, 103, 195, 0.2),rgba(64, 123, 255, 0.1));
}

.signupTopLine {
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid #D9D9D9;
    padding-bottom: 4vh;
}
.acceptedLine {
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border-bottom: 1px solid #D9D9D9;
    padding-bottom: 4vh; */
}

.signupInputField {
    width: 50%;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    height: 45px;
    outline: none;
    padding: 0 12px;
}

.signupField {
    width: 100%;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    height: 45px;
    outline: none;
    padding: 0 12px;
}

.signupInputFieldPassword {
    width: 100%;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    height: 45px;
    outline: none;
    padding: 0 12px;
}

.tooltipIcon{
    right: -7%;
}

@media only screen and (min-width: 1920px) {
    .signUpSection {
        height: 60vh;
        width: 60vw;
    }

    .signUpSectionDetails {
        width: 60vw;
        height: 77vh;
    }
    .loginSectionSignup {
        height: 50vh;
        width: 60vw;
    }
    .signUpContainerDetails{
        height: 100vh;
     overflow-y: hidden;
    }
}

@media only screen and (min-width: 1920px) {
    .tooltipIcon{
        right: -5%;
    }
}

.loginSectionSignup {
    display: flex;
    height: 62vh;
    width: 50vw;
    background: #FFFFFF80;
    box-shadow: 0px 0px 50px 0px #0000000D;
    border-radius: 10px;
    padding: 25px 30px;
    z-index: 999;
}

.smallScreenInput {
    display: flex;
    flex-wrap: nowrap; /* Default flex-wrap */
  }

@media only screen and (max-width: 567px) {
    .headerClass{
      font-size: 18px;
    }
    
}
@media only screen and (max-width: 991px) {
.formInputWrap{
    flex-direction: column;
    gap: 20px !important;
   
   }
   .signUpSectionDetails{
    width: 100%;
    margin: 0;
    overflow-x: hidden
   }

   .buttonWrap{
    flex-direction: column;
    width: 100%;
   }
   .buttonWrap button{
    width: 100%;
   }
   .tooltipIcon {
    right: -7.5%;
   }

   .infoImage{
    transform: scale(0.8);
   }
}

