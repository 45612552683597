.insertCommonInputFieldCalls input[type="checkbox"] {
  accent-color: #ff0000; /* Replace with your preferred color */
}

.list-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 20px;
  color: #9c9c9c;
}
.checked-item,
.not-checked-item {
  margin-left: 10px;
}

.list-container::-webkit-scrollbar-thumb {
  background-color: #adc3ff;
  border-radius: 10px;
}

.list-container::-webkit-scrollbar {
  width: 5px;
  /* Width of the scrollbar */
  height: 10px;
}

.meetings_edit_icon {
  opacity: 0;
}
.meetingsContainer:hover .meetings_edit_icon {
  opacity: 1;
}

.email-list {
  display: flex;
  /* flex-wrap: wrap; */
  gap: 8px;
  max-height: 120px;
  overflow-y: auto;
  border: 1.5px solid #b9b9b980;
  border-radius: 5px;
  width: 95%;
  padding: 7.5px 0px 0px 7.5px;
}

.email-item {
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  width: max-content;
  color: #515151;
}

.remove-email {
  margin-left: 8px;
  border: none;
  background: none;
  cursor: pointer;
  color: #316aff;
  font-size: 14px;
}

.lead_ownerList_container_meetings {
  overflow-x: hidden;
  overflow-y: auto !important;
  max-height: 26.9vh !important;
  min-height: max-content;
  background-color: #f0f0f0;
  width: 95%;
  top: 3.2rem;
  z-index: 10;
  box-shadow: 0px 0px 10px 0px #0000001f;
}

.lead_ownerList_container_meetings::-webkit-scrollbar {
  width: 3px;
  height: 0.2rem;
}

.lead_ownerList_container_meetings::-webkit-scrollbar-thumb {
  background-color: #adc3ff;
  border-radius: 2px;
}

.lead_ownerList_container_meetings .list-group-item {
  background-color: #ffffff;
}

.lead_ownerList_container_meetings .list-group-item:hover {
  background-color: #e5f0ff;
}
