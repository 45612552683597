.VendorContact_container span {
  padding: 5px;
}

.dropdown-container-mass {
  max-height: 50vh;
  overflow-y: auto;
  width: 26rem;
}

#mass-dropdown {
  width: 26rem;
  padding: 15px;
  border: 1px solid #b9b9b9;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #515151;
  font-size: 14px;
}

.inputEnterField {
  padding: 15px;
  border: 1px solid #b9b9b9;
  background-color: #ffffff;
}

.newSearchIcon {
  right: 12px;
  width: 20px;
}

.modal-header-title {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: #407BFF;

}

.form-label {
  font-size: 1rem;
  color: #515151;
  margin-bottom: 0.5rem;
}

.dropdown-container-mass {
  max-height: 50vh;
  overflow-y: auto;
  width: 26rem;
  background-color: rgb(242, 242, 242);
}

#mass-dropdown {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #b9b9b9;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #515151;
  font-size: 1rem;
}

.inputEnterField {
  padding: 0.5rem;
  border: 1px solid #b9b9b9;
  background-color: #ffffff;
  border-radius: 4px;
}

.newSearchIcon {
  right: 12px;
  width: 20px;
}

.btn-cancel {
  border: 1px solid #316AFF;
  color: #515151;
  width: 100px;
  background-color: #ffffff;
  border-radius: 4px;
}

.btn-update {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 135px;
  border-radius: 5px;
  background: linear-gradient(86.07deg, #407BFF 0%, #12A7FE 100%);
  border: none;
  color: #fff;
  padding: 5px 2px;
}

.leadFields .label-file {
  position: relative;
}


.leadFields .file-name {
  display: inline-block;
  max-width: 200px; 
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}

.errMssg{
  font-size: 14px;
  width: 100%;
}

.errMssg {
  font-size: 14px;
  width: 100%;
}

.imgMessege {
  max-width: 200px;
  word-wrap: break-word;
  color: rgb(250, 90, 90);
}

.bordered-container {
  border: 1px solid #e0e0e0;
  padding: 10px; 
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
}

.custom-modal-header .btn-close {
  background: url('../../Assets/uploadClosebtn.svg') no-repeat center center;
  background-size: contain;
  width: 10px; 
  height: 10px;
  opacity: 1;
  border: none;
  outline: none;
  box-shadow: none;
}

.save-button-disabled {
  opacity: 0.5;
}


