.widthContainerDataOpenActivity {
    width: 37.5%;
    border: 1.95px solid #E7E9EE;
    height: 40px;
    font-weight: 500;
    color: #515151;
    font-size: 16px;
  }

  .overviewTimelineTabOpenActivity {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(228, 245, 255, 1);
    padding: 0px 15px;
    border-radius: 18px;
    border: 1px solid rgba(82, 156, 243, 1);
  }
  
  @media screen and (min-width: 1920px) {
    .widthContainerDataOpenActivity {
      width: 22%;
      border: 1.95px solid #E7E9EE;
      height: 40px;
      font-weight: 500;
      color: #515151;
      font-size: 16px;
    }
  }
  