.creteContactBar {
  display: flex;
  width: 100%;
  height: 70px;
  align-items: center;
  /* padding: 0 10px; */
}

.contactFilter {
  border: 2px solid #316aff;
  padding: 5px;
  border-radius: 10px;
  width: 40px;
  height: fit-content;
}

.totalRecordBarContact {
  display: flex;
  width: 100%;
  height: 70px;
  align-items: center;
  padding: 0 10px;
}

.contactMain {
  display: flex;
  gap: 10px;
}

.contactLead {
  display: flex;
  flex-direction: column;
  width: 30%;
  background-color: #fff;
  height: 80vh;
  border-radius: 10px;
  padding: 10px;
  gap: 10px;
}

.contactSearch {
  border: 2px solid #a2a1a833;
  border-radius: 10px;
  outline-style: none;
  height: 35px;
  /* width: 30%; */
}

.contactListTable {
  display: flex;
  width: 100%;
  background-color: #fff;
  height: 80vh;
  border-radius: 10px;
  overflow-y: auto;
}

.contactListTable::-webkit-scrollbar {
  width: 5px;
  /* Width of the scrollbar */
  height: 10px;
}

.contactListTable::-webkit-scrollbar-thumb {
  background-color: #adc3ff;
  /* Color of the scrollbar thumb */
  border-radius: 10px;
  /* Rounded corners for the thumb */
}

.contactListTable::-webkit-scrollbar-track {
  background-color: transparent;
  /* Color of the track */
}

.createContactButtonBar {
  display: flex;
  width: 100%;
  height: 70px;
  align-items: center;
  padding: 0 10px;
  border-bottom: 3px solid #fff;
}

.createContactButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 135px;
  border: 2px solid #316aff;
  border-radius: 5px;
  padding: 5px 2px;
  background: #fff;
}

.modalButton {
  border-radius: 5px !important;
  width: 100px !important;
}

/* .createContactButton:hover {
    background: linear-gradient(86.07deg, #407BFF 0%, #12A7FE 100%);
    color: #fff;
    border: none;
} */
.contactNameInfo:hover {
  border: 1px solid black;
}

.contactFields {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 18px;
}

.createContactButtonSave {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 135px;
  border-radius: 5px;
  background: linear-gradient(86.07deg, #407bff 0%, #12a7fe 100%);
  border: none;
  color: #fff;
  padding: 5px 2px;
}
/* .lead_DetailContainer span {

    padding: 5px 2px;
} */
