.creteLeadBar {
  display: flex;
  width: 100%;
  height: 70px;
  align-items: center;
  /* padding: 0 10px; */
}

.totalRecordBar {
  display: flex;
  width: 100%;
  height: 70px;
  align-items: center;
  padding: 0 10px;
}

.leadMain {
  display: flex;
  gap: 10px;
}

.filterLead {
  display: flex;
  flex-direction: column;
  width: 30%;
  background-color: #fff;
  height: 80vh;
  border-radius: 10px;
  padding: 10px;
  gap: 10px;
}

.table.accountTable th,
.table.accountTable td {
  width: calc(100% / 4); /* Set the width for each column */
  white-space: nowrap; /* Prevent text wrapping */
}

.table.accountTable th:first-child,
.table.accountTable td:first-child {
  width: 50px; /* Set the width for the first column (checkbox) */
}

.accountListTable {
  /* display: flex; */
  width: 100%;
  background-color: #fff;
  height: 80vh;
  border-radius: 10px;
  overflow-y: auto;
}

.accountListTable::-webkit-scrollbar {
  width: 5px;
  /* Width of the scrollbar */
  height: 10px;
}

.accountListTable::-webkit-scrollbar-thumb {
  background-color: #adc3ff;
  /* Color of the scrollbar thumb */
  border-radius: 10px;
  /* Rounded corners for the thumb */
}

.accountListTable::-webkit-scrollbar-track {
  background-color: transparent;
  /* Color of the track */
}

.filterSearch {
  border: 2px solid #a2a1a833;
  border-radius: 10px;
  outline-style: none;
  height: 35px;
  /* width: 30%; */
}

.checkboxItemAccount {
  color: #9c9c9c;
}

.createLeadButtonBar {
  display: flex;
  width: 100%;
  height: 70px;
  align-items: center;
  padding: 0 10px;
  border-bottom: 3px solid #fff;
}

.createLeadButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  border: 2px solid #316aff;
  border-radius: 10px;
}

/* .createLeadButton:hover {
      background: linear-gradient(86.07deg, #407BFF 0%, #12A7FE 100%);
      color: #fff;
      border: none;
  } */

.leadInputField {
  width: 60%;
  border: 1px solid #b9b9b9bf;
  height: 42px;
  outline: none;
  border-radius: 10px;
  padding: 12px;
}

.leadFields {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 10px;
}

.accountInputFieldDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.accountInputFieldDivLeft {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 10px;
}

.accountInputFieldDivRight {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 10px;
}

.leadFilter {
  border: 2px solid #316aff;
  padding: 5px;
  border-radius: 10px;
  width: 40px;
  height: fit-content;
}

/* individualDetailPage css  */

.accountLogoContainer {
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  overflow: hidden; /* Ensure the image is clipped to the container's border-radius */
}

.accountLogoContainer img {
  height: 100%;
  width: 100%;
  object-fit: fill; /* Ensure the image covers the container without stretching */
}
.accountBtnColor {
  background: linear-gradient(86.07deg, #407bff 0%, #12a7fe 100%) !important;
}
.accountRelatedListContainer ul {
  list-style: none;
  margin: 2px 0 0 0;
  padding: 10px;
}

.accountRelatedListContainer li {
  padding-top: 8px;
  display: flex;
  gap: 5px;
}

.accountHorizontall_line {
  border: 0.5px solid black;
}

.selectAccountTab {
  background-color: #e4f5ff !important;
}

.imageAccButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  padding: 5px 0px;
  border: 2px solid #316aff;
  border-radius: 10px;
}

.imageAccButtonRemove {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  padding: 5px 0px;
  border: 2px solid #316aff;
  border-radius: 20px;
}
.imageAccButtonSave {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  padding: 5px 0px;
  border-radius: 10px;
  background: linear-gradient(86.07deg, #407bff 0%, #12a7fe 100%);
  border: none;
  color: #fff;
}

.form-group {
  width: 30%;
  display: block;
  position: relative;
  /* margin-bottom: 10px; */
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.file-control {
  display: none;
}
.label-file {
  width: 100%;
  height: 42px;
  display: inline-block;
  position: relative;
  background: rgba(228, 245, 255, 1);
  padding: 5px 50px 5px 5px;
  border-radius: 4px;
  border: 2px dotted #42a5f5;
  vertical-align: middle;
  overflow: hidden;
  /* line-height: 30px; */
}
.label-file:after {
  content: "";
  position: absolute;
  /* background-color: #42a5f5; */
  background-image: url("../../Assets/folder-upload.png");
  right: 0;
  top: 5px;
  height: 29px;
  width: 29px; /* Set a width for the icon container */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 0 4px 4px 0; /* Adjust border radius to match the label */
}
