.selectedOverview {
  background-color: #dde9f6;
  color: #202123;
  font-weight: 500;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.unselectedOverview {
  color: #515151;
  padding: 5px;
  cursor: pointer;
}

.addComponent {
  color: #ffffff;
  background: linear-gradient(86.07deg, #407bff 0%, #12a7fe 100%);
  border-radius: 8px;
  border: 1px solid;
}

.overviewContent {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 4px;
  border: 1px solid #ffffff;
  /* overflow: hidden; */
  white-space: nowrap;
  text-overflow: ellipsis;
}
.wrap_text {
  white-space: wrap;
}
.overviewHeaders {
  font-size: 15px;
  color: #050505;
  /* font-weight: 600; */
  /* overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    position: relative; */
}
.apexcharts-datalabels-group text {
  font-size: 14px;
}
.triangleGreen {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #12aa67;
}

.leadsTarget {
  border-radius: 4px;
}

/* .apexcharts-datalabels{
    display: none;
  } */

.percent {
  background-color: #12aa67;
  color: #ffffff;
  border-radius: 6px;
}

.border_radius {
  border-radius: 5px;
}

.leadsHeader {
  color: #202123;
  font-weight: 500;
  font-size: 16px;
}
.canvasjs-chart-credit {
  display: none;
}
.headerCell {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: center;
  font-weight: bold;
  background-color: #f2f2f2;
}

.cell {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: center;
}
.sales_trend_dropdown:hover {
  outline: 1px solid gray;
}
.users_container {
  /* background-color: #dbdada; */
  z-index: 2;
  box-shadow: 1px 4px 6px 4px rgba(0, 0, 0, 0.1);
}
.users_container_input {
  border-bottom: 1px solid gray;
}
.users_container_input input,
.borderless_input {
  outline: none;
  border: none;
}
.users_container_list {
  max-height: 15rem;
  overflow-y: auto;
}
.users_container_list_item:hover {
  background-color: #dde9f6;
}
/* .multiline_chart_container{} */
/* .analytics_table .table thead tr {
  background-color: #12a7fe !important;
} */
