.tableContainerDiv {
    background-color: white;
}

.selectTab {
    background: linear-gradient(86.07deg, #407bff 0%, #12a7fe 100%);
    color: #fff;
    border-radius: 0px;
  } 

  .deSelect{
    color:#676767
  }

  .table-wrapper {
    max-width: 100%;
    height: 69vh;
    overflow-y: auto;
    overflow-x: auto;
    position: relative;
}

.Owner_table_container_reports {
    height: 20%;
    margin-top: 12px;
    border: 2px solid #e2e5eb;
    padding: 0;
    overflow: hidden;
    width: 28%;
}

@media only screen and (min-width: 1920px) {
    .Owner_table_container_reports {
        width: 25%;
    }
}