.upgradeHeader{
    color:#515151;
    font-size: 28px;
    font-weight: 400;
}

.selectedPlanClass{
    background-color: #ffffff;
    border-radius: 30px;
    border: 1px solid #ffffff;
    box-shadow: 0px 0px 30px 2px rgba(64, 123, 255, 0.2);
    width: fit-content;
}

.recommendedClassTop{
    background-color: #ffffff;
    border-radius: 2px;
    border: 1px solid #ffffff;
    box-shadow: 0px 0px 30px 2px rgba(64, 123, 255, 0.2);
}

.selectedPlan{
    background-color: rgba(33, 119, 254, 0.2);
    border-radius: 30px;
}

.deselectedPlan{
    color:#515151
}

.planDetails{
    background: linear-gradient(to bottom,rgba(82, 156, 243, 0.2),rgba(147, 4, 168, 0.1));
    border-radius: 15px;
    width:19vw

}


.planData{
    color:#515151;
    font-weight: 400;
    font-size: 50px;
}

.userSubscribe{
    color:#515151;
    font-weight: 400;
    font-size: 20px; 
}

.listClass{
    color: #515151;
    font-weight: 300;
    font-size: 16px;
}

.planButton{
    border-radius: 30px;
    border: 1px solid  rgba(222, 199, 255, 1);
    background-color: #ffffff;
    width: 16vw;
    color:#515151;
    font-weight: 400;
    font-size: 16px;
    position: absolute;
    bottom: 6%;
}

.recommendedClass{
   color: #ffffff;
   background: linear-gradient(86.07deg, #407BFF 0%, #12A7FE 100%);
}

.learnMoreDetails{
    color:#515151;
    font-size: 25px;
    font-weight: 400;
}

.learnMoreHeader{
    background: linear-gradient(to right,rgba(0, 255, 26, 0.2),rgba(112, 0, 255, 0.2)); 
   border-radius: 15px;
   width:50vw
}

.learnMoreButton{
    border-radius: 30px;
    border: 1px solid  rgba(222, 199, 255, 1);
    background-color: #ffffff;
    width: 16vw;
    color:#515151;
    font-weight: 400;
    font-size: 16px;
}

.standardButton{
    color: #ffffff;
    background: linear-gradient(to right,rgba(199, 159, 255, 1),rgba(151, 196, 255, 1));
    border-radius: 30px;
    font-size: 16px;
}

.proceedButton{
   border-radius: 20px;
   border: 1px solid #DEC7FF;
   text-align: center; 
   color:#515151;
   font-size: 16px;
   font-weight: 400;
}

.upgradeInputBox{
  border: 1px solid #E7E7E7; 
  border-radius: 5px;
   color: #515151;
   font-size: 15px;
   outline: none;
   width: 26vw;

}

.upgradeInputBoxCity{
    border: 1px solid #E7E7E7; 
    border-radius: 5px;
     color: #515151;
     font-size: 15px;
     outline: none;
     width: 19vw;
}

.upgradeInputBoxZip{
    border: 1px solid #E7E7E7; 
    border-radius: 5px;
     color: #515151;
     font-size: 15px;
     outline: none;
     width: 110px;
}

.upgradeInputBoxGstin{
    border: 1px solid #E7E7E7; 
    border-radius: 5px;
     color: #515151;
     font-size: 15px;
     outline: none;
     width: 24vw; 
}

.gstinClass{
    border: 1px solid #E7E7E7;
    border-radius: 10px;
    width:28vw
}

.paymentSuccess{
    border: 1px solid  #E7E7E7;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: rgba(82, 156, 243, 0.1);
}

.dashboardButton{
   color:#ffffff;
   border-radius: 30px;
   background: linear-gradient(to right,rgba(199, 159, 255, 1),rgba(151, 196, 255, 1));
   border:1px solid rgba(199, 159, 255, 1) ;
}

.upgradeInfoContainer {
    display: block;
    position: absolute;
    top: 3.6rem;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 5;
    right: 0.1rem;
    min-width: 14rem;
    max-width: 18rem;
  }

  .numberInputBox{
    outline: none;
    border: none;
   text-align: end;
   border-bottom: 2px solid #B9B9B9;
    width: 5vw;
  }

  