.dealStage{
   color: #515151;
    background-image: url('../../Assets/Rectangle\ 3463452\ \(1\).svg');
    background-repeat: no-repeat;
    /* background-color: #E4F5FF; */
    border-radius: 10px;
    background-position: 50% 50%;
   }
   
   .dealUnstage{
      background-image: url('../../Assets/SliderActive.svg');
      background-repeat: no-repeat;
      background-position: 50% 50%;
   }

.inputBoxDeal{
   outline: none;
   border: 1.5px solid #B9B9B9 ;
   border-radius: 5px;
   color: #515151;
   /* height: 40px; */
   /* width: 20vw; */
   width: 250px;
   height: 42px;

}

.input-with-clear .clear-button-campaign {
   position: absolute;
   top: 50%;
   right: 10px !important;
   transform: translateY(-50%);
   background: transparent;
   border: none;
   cursor: pointer;
   }

.reasonSelectDropdown{
   outline: none;
   border: 1.5px solid #B9B9B9 ;
   border-radius: 5px;
   color: #515151;
   height: 7vh;
   width: 18vw;
}
