.taskMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
}

.taskButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  border: 2px solid #316aff;
  border-radius: 10px;
  background-color: #316aff;
  color: #fff;
}
.tasksField {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 10px;
}

.leadInputFieldModal {
  /* width: 100%; */
  border: 1px solid #b9b9b9b0;
  height: 42px;
  outline: none;
  background-color: transparent !important;
  border-radius: 4px !important;
  padding: 12px;
}

.lead_ownerList_task_container {
  overflow-y: auto !important;
  max-height: 21.9vh !important;
  min-height: 7.9vh !important;
  background-color: #f0f0f0;
  width: 24.5vw;
  top: 336px;
  z-index: 10;
  box-shadow: 0px 0px 10px 0px #0000001f;
}

.selected-item {
  background-color: #e7f2ff;
  border-radius: 5px;
  cursor: pointer;
}

.testHeader {
  font-size: 14px;
  color: #515151;
  font-weight: 300;
}

.showOptions {
  position: absolute;
  top: 9.5rem;
  right: 2%;
  border: 1px solid #ffffff;
  border-radius: 2px;
  height: auto;
  width: 15vw;
  background-color: #ffffff;
}



