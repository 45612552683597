.verifyButton{
    border-radius: 5px;
    width: 14vw;
    background: linear-gradient(to right,rgba(64, 123, 255, 1), rgba(18, 167, 254, 1));
    border: 1px solid rgba(64, 123, 255, 1) ;
    color: #ffffff;
    font-size: 16px;
}

.accountActivation{
    background: linear-gradient(to right,rgba(126, 103, 195, 0.5),rgba(255, 255, 255, 0.9));
}

.accountSuccess{
    border: 1px solid  #E7E7E7;
    border-radius: 10px;
    background-color:  rgba(255, 255, 255, 0.5);
    box-shadow:rgba(0, 0, 0, 0.05);
}