.loginContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background: linear-gradient(to right,
    rgba(126, 103, 195, 0.2),rgba(64, 123, 255, 0.1));
    position: relative;
    overflow: hidden;
}

.rotateEffect {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    box-shadow: 0 0 20px rgba(68, 171, 253, 0.5);
    animation: moveCircle 6s linear infinite;
  }
  
  @keyframes moveCircle {
    0% {
      top: 0;
      left: 0;
    }
    25% {
      top: 0;
      left: calc(100vw - 100px);
    }
    50% {
      top: calc(100vh - 100px);
      left: calc(100vw - 100px);
    }
    75% {
      top: calc(100vh - 100px);
      left: 0;
    }
    100% {
      top: 0;
      left: 0;
    }
  }

.loginSection {
    display: flex;
    height: auto;
    width: 70vw;
    background: #FFFFFF80;
    box-shadow: 0px 0px 50px 0px #0000000D;
    border-radius: 10px;
    padding: 25px 30px;
    z-index: 999;
}



.loginLeft {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    align-items: self-start;
    border-right: 2px solid #D9D9D9;
    padding: 0px 10px;
}

.loginRight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    justify-content: space-evenly;
    gap: 40px;
    padding: 0px 15px;
    width: 50%;
}

.loginInput {
  width: 100%;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loginEmailField {
    /* width: 100%; */
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    height: 45px;
    outline: none;
    padding: 0 5px;
}

.loginEmailField::placeholder {
    font-size: 14px;
}

.LoginNextBtn {
    background: linear-gradient(86.07deg, #407BFF 0%, #12A7FE 100%);
    outline: none;
    border-radius: 5px;
    border: none;
    height: 45px;
    color: #fff;
}

.loginDiv {
    display: flex;
    align-items: center;
    color: #000;
    gap: 30px;
    padding-left: 25px;
}

@media only screen and (min-width: 1920px) {
    .loginSection {
        height: 55vh;
        width: 53vw;
    }
}

.otp-input {
    width: 50px;
    height: 35px;
    font-size: 24px; 
    margin: 5px; 
    border:1px solid #D9D9D9;
}