.commonContainer_bg {
  background-color: #ebeef3 !important;
}
.cursorPointer {
  cursor: pointer;
}

#commonLeadModal {
  display: flex;
  justify-content: center;
  border-radius: 5px;
  /* width: 24.5vw; */
}
.drag-over {
  border: 2px dashed #007bff;
  background-color: #f8f9fa;
}
.commonTextColor {
  color: #407bff !important;
  /* background: linear-gradient(to right, #30CFD0 0%, #330867 100%); */
  background: linear-gradient(86.07deg, #407bff 0%, #12a7fe 100%);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#commonLeadModal .modal-content {
  border-radius: 3%;
  box-shadow: 0px 0px 29.6px 0px #00000033;
  padding: 0px 10px;
  margin-top: 2rem;
}

.topBarModalSearch {
  border: 1.5px solid #9c9c9c75;
  border-radius: 29px;
  outline-style: none;
  height: 42px;
  width: 14vw;
  background-color: #ffffff;
}
.fs_small {
  font-size: 12px;
}

/* .lead_DetailContainer div {
  padding: 4px 0px;
} */

.relateList_container li {
  padding: 8px 0px;
}
.relateList_container span {
  margin: 0px 4px;
}

.overviewTimelineTab {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(228, 245, 255, 1);
  padding: 0px 9px;
  border-radius: 18px;
  border: 1px solid rgba(82, 156, 243, 1);
}

.insertCommonInputField {
  width: 60%;
  border: 1px solid #b9b9b9b0;
  height: 42px;
  outline: none;
  background-color: transparent !important;
  border-radius: 4px !important;
  padding: 12px;
}
.mandatoryField {
  width: 60%;
  border: 1px solid #f42828 !important;
  height: 42;
  outline: none;
  background-color: transparent !important;
  border-radius: 4px !important;
  padding: 12px;
}

#dropdown-contact {
  background-color: transparent;
  color: #000 !important;
  border-radius: 5px;
  border: 1px solid rgba(245, 245, 245, 0.2);
  height: 5vh;
  width: 13vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inputFieldDivLeft {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 3px;
  padding-top: 1.5rem;
}

.inputFieldDivRight {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 3px;
  padding-top: 1.5rem;
}

.inputFieldDivLeft,
.inputFieldDivRight p {
  color: #515151;
}

.profilePhoto {
  width: 300px;
  height: 300px;
  object-fit: contain; /* or object-fit: cover; depending on your needs */
}
.profilePhotoNotes {
  width: 500px;
  height: 400px;
  object-fit: contain; /* or object-fit: cover; depending on your needs */
}
.inputFile {
  font-size: 1.2vw;
  border: 1.5px solid rgba(81, 81, 81, 0.3);
  border-radius: 10px;
  padding: 7px 0px;
  width: 100%;
  text-indent: 0;
  text-overflow: ellipsis;
  padding-right: 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.backBtn {
  width: 32% !important;
  cursor: pointer;
  padding: 4px 3px !important;
}

.backBtn:hover {
  background-color: #12a7fe;
}

.editNoteButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  border-radius: 20px;
  background: linear-gradient(86.07deg, #407bff 0%, #12a7fe 100%);
  border: none;
  color: #fff;
  padding: 5px 2px;
}

.relatedListButton {
  background-color: #e4f5ff;
  border-radius: 8px;
  border: 1px solid #529cf3;
  color: #515151;
  text-align: start;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lead_ownerList_individual_container {
  overflow-y: auto !important;
  max-height: 26.9vh !important;
  min-height: 25.9vh !important;
  background-color: #f0f0f0;
  /* width: 24.5vw;
  top: 336px; */
  z-index: 10;
  box-shadow: 0px 0px 10px 0px #0000001f;
}

.lead_ownerList_individual_container::-webkit-scrollbar,
.users_container_list::-webkit-scrollbar,
.hours_options::-webkit-scrollbar {
  width: 3px;
}
.memberList_container {
  overflow-y: auto !important;
  height: 39vh !important;
}
.analytics_table table {
  height: 100% !important;
  margin-bottom: unset !important;
}

.memberList_container::-webkit-scrollbar,
.analytics_table::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.users_container_list::-webkit-scrollbar-thumb,
.lead_ownerList_individual_container::-webkit-scrollbar-thumb,
.hours_options::-webkit-scrollbar-thumb,
.memberList_container::-webkit-scrollbar-thumb,
.analytics_table::-webkit-scrollbar-thumb {
  background: #adc3ff;
  border-radius: 2px;
}
.users_container_list::-webkit-scrollbar-track,
.lead_ownerList_individual_container::-webkit-scrollbar-track,
.hours_options::-webkit-scrollbar-track,
.memberList_container::-webkit-scrollbar-track,
.analytics_table::-webkit-scrollbar-track {
  background-color: rgb(245, 245, 245);
}
.addedList_container {
  overflow-y: auto !important;
  height: 47vh !important;
}

.addedList_container::-webkit-scrollbar {
  width: 3px;
}

.addedList_container::-webkit-scrollbar-thumb {
  background: #adc3ff;
  border-radius: 2px;
}
/* .roleList:hover {
background-color: #529cf3;
} */

.erro_msg {
  color: #fa5a5a;
  /* height: 0.8rem; */
}
.overflow_text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.overflow_text_hash {
  white-space: nowrap;
  text-overflow: ellipsis;
}
.popout_box {
  top: 2.5rem;
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.2);
  min-width: 18rem !important;
  z-index: 10;
}
.discount_icon {
  opacity: 0;
}
.dicount_column:hover .discount_icon {
  opacity: 1;
}
/* .createContactButton:hover{
  background: linear-gradient(86.07deg, #407BFF 0%, #12A7FE 100%);
  color: #fff;
  border: none;
} */

.deleteModal .modal-dialog {
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
}
.paginationArrow {
  color: rgba(33, 119, 254, 1);
  font-size: 20px;
  cursor: pointer;
  padding: 0px 8px 4px 8px;
  text-align: center;
}
.paginationArrowDisable {
  font-size: 20px;
  cursor: pointer;
  padding: 0px 8px 4px 8px;
  text-align: center;
  opacity: 0.5;
}
.paginationArrow:hover {
  color: #fff;
  background-color: rgba(33, 119, 254, 1);
  border-radius: 20px;
  padding: 0px 8px 4px 8px;
}

.addTaskContainer {
  outline: none;
  color: #515151;
  height: 4vh;
  width: 11vw;
  border: none;
  /* border-top: none ;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid black; */
}

.manageSharingBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: linear-gradient(86.07deg, #407bff 0%, #12a7fe 100%);
  border: none;
  color: #fff;
}

.BorderBottom {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid black;
}

.custom-tooltip {
  max-width: 370px;
  padding: 5px;
  font-size: 14px;
  line-height: 1.4;
}

.custom-tooltip::after {
  content: attr(data-tooltip-content);
  white-space: normal;
}

.productListCommon {
  width: 100%;
  background-color: #fff;
  height: auto;
  border-radius: 10px;
  overflow-y: auto;
}

#commonLeadModalUsers {
  display: flex;
  justify-content: center;
  border-radius: 5px;
  width: 40vw;
}

.first_letter_uppercase::first-letter {
  text-transform: uppercase;
}

.table_report {
  font-size: 0.7rem !important;
}

#commonLeadModalExcel {
  display: flex;
  justify-content: end;
  border-radius: 5px;
  position: absolute;
  bottom: -20px;
  right: 5px;
}

.relatedListCallContainer {
  height: 100% !important;
  overflow-x: hidden;
  margin-left: 0px;
  overflow-y: scroll;
}
/* @media (min-width: 1024px) {
  .table_report {
    font-size: 0.7rem !important; 
  }
} */

@media only screen and (min-width: 1920px) {
  .table_report {
    font-size: 1rem !important;
  }
}

/* @media screen and (min-width: 1920px) {
  .table_report  {
    right: 330px !important;
  }
} */
@supports (-moz-appearance: none) {
  .inputFile {
    text-indent: 0%;
  }
}

.imageContainer {
  position: relative;
}

.imageThumbnail {
  display: block;
}

.imageOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.imageContainer:hover .imageOverlay {
  opacity: 1;
}

.icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.fileName {
  display: flex;
  align-items: center;
  border: 1px solid #a5a5a5;
  height: 60px;
  width: 60px;
  font-size: 14px;
}
